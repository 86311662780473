import React, {useState} from 'react'
import styled from 'styled-components'
import {graphql} from 'gatsby'
import {MainLayout} from 'layouts'
import {ProjectCard, SEO, SearchBar} from 'components'
import {useAmplitude} from 'hooks'

const Browser = styled.div`
  display: flex;
  flex-direction: column;

  width: var(--mobile-width);
  margin: 25px 0;

  @media only screen and (min-width: 1200px) {
    max-width: var(--content-width);
  }
`

const Title = styled.div`
  margin-bottom: 15px;

  h1 {
    margin: 0;
  }
`

const CardGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 20px;

  @media only screen and (min-width: 600px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media only screen and (min-width: 850px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media only screen and (min-width: 1100px) {
    grid-template-columns: repeat(4, 1fr);
  }
`

const GriddableProjectCard = styled(ProjectCard)`
  width: 100%;
  max-width: unset;
`

const SearchBarBox = styled.div`
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: 700px) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
`

export const ProjectsPage = ({data}) => {
  const {logEvent} = useAmplitude()
  const [projects, setProjects] = useState(data.allStrapiProject.nodes)

  const logClick = (buttonName, data = {}) => logEvent('projects-page-button-click', {buttonName, ...data})

  return (
    <MainLayout theme='transparent'>
      <SEO title='Projects' />
      <Browser>
        <Title>
          <h1>Projects</h1>
          <SearchBarBox>
            <p>Tutorials for building apps with real-world applications.</p>
            <SearchBar
              documents={projects}
              setDocuments={setProjects}
              label='Search our projects'
              index={['slug', 'title', 'description', 'categories.tag']}
            />
          </SearchBarBox>
        </Title>
        <CardGrid>
          {projects.length === 0 ? (
            <h2>No results.</h2>
          ) : (
            projects.map((project) => (
              <GriddableProjectCard
                key={project.slug}
                project={project}
                onClick={() => logClick('project-preview', {slug: project.slug})}
              />
            ))
          )}
        </CardGrid>
      </Browser>
    </MainLayout>
  )
}

export default ProjectsPage

export const Projects = graphql`
  query Projects {
    allStrapiProject(sort: {fields: free, order: DESC}) {
      nodes {
        slug
        title
        categories {
          tag
        }
        free
        level
        gatsbyIcon {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        description
        lessons {
          video {
            duration
          }
        }
        introduction {
          duration
        }
      }
    }
  }
`
